import React, { useEffect, useState } from "react";
import '../../assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
const Sidebar = () => {
  const user = useUser();
  const UserData = user.user;
  const SusbcriptionData = user.subscriptionStaff;

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    setIsScrolled(scrollTop > 0);
  };

  // const permissions = {
  //   residents: {
  //     dashboard: UserData?.facility?.is_resident_module_enabled && UserData?.user?.role !== 'examiner' && UserData?.user?.facility_user?.can_see_residents,
  //     list: UserData?.facility?.is_resident_module_enabled && UserData?.user?.facility_user?.can_see_residents && UserData?.user?.role !== 'trainings_user',
  //     add: UserData?.facility?.is_resident_module_enabled && UserData?.user?.facility_user?.can_see_residents && UserData?.user?.role !== 'trainings_user',
  //     admission: UserData?.facility?.is_resident_module_enabled && UserData?.user?.facility_user?.can_see_residents && UserData?.user?.role !== 'trainings_user' && UserData?.user?.role !== 'examiner',
  //   },
  //   staff: {
  //     dashboard: UserData?.user?.role == 'trainings_user' ||  UserData?.user?.role !== 'examiner',
  //     list: UserData?.user?.facility_user?.can_see_staff && UserData?.user?.role !== 'examiner' && UserData?.user?.role !== 'trainings_user',
  //     add: UserData?.user?.facility_user?.can_see_staff && UserData?.user?.role !== 'examiner' && UserData?.user?.role !== 'trainings_user',
  //     schedule: UserData?.user?.facility_user?.can_see_staff && UserData?.user?.role !== 'examiner' && UserData?.user?.role !== 'trainings_user',
  //     courses: UserData?.user?.facility_user?.can_see_staff && UserData?.user?.role !== 'examiner' && UserData?.user?.role !== 'trainings_user' && UserData?.user?.role !== 'manager',
  //     customTrainings: UserData?.user?.facility_user?.can_see_staff && UserData?.user?.role !== 'examiner' && UserData?.user?.role !== 'trainings_user',
  //   },
  //   account: {
  //     creditCard: UserData?.user?.role !== 'examiner' && UserData?.user?.role !== 'trainings_user' && SusbcriptionData?.has_stripe_id === false,
  //     facility: UserData?.user?.role !== 'examiner' && UserData?.user?.role !== 'trainings_user',
  //   }
  // };


  const permissions = {
    residents: {
      dashboard: UserData?.facility?.is_resident_module_enabled &&
        UserData?.user?.role !== 'examiner',
        //  &&
        // UserData?.user?.facility_user?.can_see_residents,

      list: UserData?.facility?.is_resident_module_enabled &&
        // UserData?.user?.facility_user?.can_see_residents,
        //  &&
        UserData?.user?.role !== 'trainings_user',

      add: UserData?.facility?.is_resident_module_enabled &&
        // UserData?.user?.facility_user?.can_see_residents &&
        UserData?.user?.role !== 'trainings_user' &&
        UserData?.user?.role !== 'examiner',

      admission: UserData?.facility?.is_resident_module_enabled &&
        // UserData?.user?.facility_user?.can_see_residents &&
        UserData?.user?.role !== 'trainings_user' &&
        UserData?.user?.role !== 'examiner',
    },

    staff: {
      dashboard: UserData?.user?.role === 'trainings_user' ||
        (UserData?.facility?.is_resident_module_enabled && UserData?.user?.role !== 'examiner'),

      list: 
      // UserData?.user?.facility_user?.can_see_staff &&
        UserData?.user?.role !== 'examiner' &&
        UserData?.user?.role !== 'trainings_user',

      add: 
      // UserData?.user?.facility_user?.can_see_staff &&
        UserData?.user?.role !== 'examiner' &&
        UserData?.user?.role !== 'trainings_user',

      schedule: 
      // UserData?.user?.facility_user?.can_see_staff &&
        UserData?.user?.role !== 'examiner' &&
        UserData?.user?.role !== 'trainings_user',

      courses: 
      // UserData?.user?.facility_user?.can_see_staff &&
        UserData?.user?.role !== 'examiner' &&
        UserData?.user?.role !== 'trainings_user', // Removed `manager` restriction for consistency

      customTrainings: 
      // UserData?.user?.facility_user?.can_see_staff &&
        UserData?.user?.role !== 'examiner' &&
        UserData?.user?.role !== 'trainings_user',
    },

    account: {
      creditCard: UserData?.user?.role !== 'examiner' &&
        UserData?.user?.role !== 'trainings_user' &&
        SusbcriptionData?.has_stripe_id ===  false, // Aligns with `showPayNowButton`

      facility: UserData?.user?.role !== 'examiner' &&
        UserData?.user?.role !== 'trainings_user',
    }
  };

  const residentsPermissions = permissions.residents;
  const staffPermissions = permissions.staff;
  const accountPermissions = permissions.account;

  // Check if at least one resident permission is available
  const hasResidentPermissions = Object.values(residentsPermissions).some(Boolean);
  const hasStaffPermissions = Object.values(staffPermissions).some(Boolean);
  const hasAccountPermissions = Object.values(accountPermissions).some(Boolean);


  return (
    <PerfectScrollbar onScroll={handleScroll} className="custom-scrollbar">
      <div className={`nav-wrapper ${isScrolled ? 'scrolled' : ''}`}>
        <ul
          id="nav"
          className="nav"
          data-slim-scroll
          data-collapse-nav
          data-highlight-active
        >

          {/* <li className="nav-header">
          <span>Residents</span>
        </li>
        

        {permissions.residents.dashboard && (
          <li className="{permissions.residentNavClass}">
            <a href="/resident-dashboard" hide-off-canvas="true">
              <i className="nav-icon fa fa-dashboard" />
              <span>Resident Dashboard</span>
            </a>
          </li>
        )}

        {permissions.residents.list && (
          <li className="{permissions.residentNavClass}">
            <a href="/residentlist" hide-off-canvas="true">
              <i className="nav-icon fa fa-users" />
              <span>Resident List</span>
            </a>
          </li>
        )}

        {permissions.residents.add && (
          <li className="{permissions.residentNavClass}">
            <a href="/add-resident" hide-off-canvas="true">
              <i className="nav-icon fa fa-user-plus" />
              <span>Add Resident</span>
            </a>
          </li>
        )}

        {permissions.residents.admission && (
          <li className="{permissions.residentNavClass}">
            <a href="admission-log/" hide-off-canvas="true">
              <i className="nav-icon fa fa-users" />
              <span>Admission / Discharge Log</span>
            </a>
          </li>
        )} */}

          {hasResidentPermissions && (
            <li className="nav-header">
              <span>Residents</span>
            </li>
          )}

          {/* Resident Dashboard */}
          {residentsPermissions.dashboard && <li className="{permissions.residentNavClass}">
            <a href="/resident-dashboard" hide-off-canvas="true">
              <i className="nav-icon fa fa-dashboard" />
              <span>Resident Dashboard</span>
            </a>
          </li>
          }

          {/* Resident List */}
          {residentsPermissions.list &&
            <li className="{permissions.residentNavClass}">
              <a href="/residentlist" hide-off-canvas="true">
                <i className="nav-icon fa fa-users" />
                <span>Resident List</span>
              </a>
            </li>
          }

          {/* Resident Add */}
          {residentsPermissions.add &&
            <li className="{permissions.residentNavClass}">
              <a href="/add-resident" hide-off-canvas="true">
                <i className="nav-icon fa fa-user-plus" />
                <span>Add Resident</span>
              </a>
            </li>
          }

          {/* Resident Admissions */}
          {residentsPermissions.admission &&
            <li className="{permissions.residentNavClass}">
              <a href="/admission-log" hide-off-canvas="true">
                <i className="nav-icon fa fa-users" />
                <span>Admission / Discharge Log</span>
              </a>
            </li>
          }


          {/* <li className="nav-header">
          <span>Staffing</span>
        </li>


        {permissions.staff.dashboard && (
          <li>
            <a href="/dashboard" hide-off-canvas="true">
              <i className="nav-icon fa fa-dashboard" />
              <span>Employee Dashboard</span>
            </a>
          </li>
        )}

        {permissions.staff.list && (
          <li>
            <a href="/employeelist" hide-off-canvas="true">
              <i className="nav-icon fa fa-users" />
              <span>Employee List</span>
            </a>
          </li>
        )}

        {permissions.staff.add && (
          <li>
            <a href="/add-employee" hide-off-canvas="true">
              <i className="nav-icon fa fa-user-plus" />
              <span>Add Employee</span>
            </a>
          </li>
        )}

        {permissions.staff.schedule && (
          <li>
            <a href="/training-events" hide-off-canvas="true">
              <i className="nav-icon fa fa-book" />
              <span>Scheduled Trainings</span>
            </a>
          </li>
        )}
        {permissions.staff.courses && (
          <li>
            <a href="/courses" hide-off-canvas="true">
              <i className="nav-icon fa fa-graduation-cap" />
              <span>Courses</span>
            </a>
          </li>
        )}
        {permissions.staff.customTrainings && (
          <li>
            <a
              href="/tasktypes"
              data-staff-subscription-required="true"
              hide-off-canvas="true"
            >
              <i className="nav-icon fa fa-plus" />
              <span>Custom Trainings</span>
            </a>
          </li>
        )}
 */}

          {hasStaffPermissions &&
            <li className="nav-header">
              <span>Staffing</span>
            </li>
          }


          {staffPermissions.dashboard && (
            <li>
              <a href="/dashboard" hide-off-canvas="true">
                <i className="nav-icon fa fa-dashboard" />
                <span>Employee Dashboard</span>
              </a>
            </li>
          )}

          {staffPermissions.list && (
            <li>
              <a href="/employeelist" hide-off-canvas="true">
                <i className="nav-icon fa fa-users" />
                <span>Employee List</span>
              </a>
            </li>
          )}

          {staffPermissions.add && (
            <li>
              <a href="/add-employee" hide-off-canvas="true">
                <i className="nav-icon fa fa-user-plus" />
                <span>Add Employee</span>
              </a>
            </li>
          )}

          {staffPermissions.schedule && (
            <li>
              <a href="/training-events" hide-off-canvas="true">
                <i className="nav-icon fa fa-book" />
                <span>Scheduled Trainings</span>
              </a>
            </li>
          )}
          {staffPermissions.courses && (
            <li>
              <a href="/courses" hide-off-canvas="true">
                <i className="nav-icon fa fa-graduation-cap" />
                <span>Courses</span>
              </a>
            </li>
          )}
          {staffPermissions.customTrainings && (
            <li>
              <a
                href="/tasktypes"
                data-staff-subscription-required="true"
                hide-off-canvas="true"
              >
                <i className="nav-icon fa fa-plus" />
                <span>Custom Trainings</span>
              </a>
            </li>
          )}

          {/* {hasAccountPermissions && */}
            <li className="nav-header">
              <span>Account</span>
            </li>
          {/* } */}


          {accountPermissions.creditCard && (
            <li>
              <a
                href="/subscription-staff/?updateTrial=true"
                hide-off-canvas="true"
              >
                <i class="nav-icon fa-regular fa-money-bill-1"></i>
                <span>Add credit card</span>
              </a>
            </li>
          )}

          {accountPermissions.facility && (
            <li>
              <a href="/facility-details" hide-off-canvas="true">
                <i className="nav-icon fa-solid fa-building" />
                <span>Facility Details</span>
              </a>
            </li>
          )}

          {UserData?.facility?.bought_ebook &&
            <li>
              <a
                className="{permissions.ahcaxietyClass}"
                href="http://alfboss.com/wp-content/uploads/2018/07/Cure-Your-AHCAXIETY-ebook_06.pdf"
                hide-off-canvas="true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="nav-icon fa fa-bookmark" />
                <span>Curing AHCAxiety</span>
              </a>
            </li>
          }

          {UserData?.user?.role !== 'examiner' &&
            <li>
              <a href="/ahca-regulations" hide-off-canvas="true">
                <i className="nav-icon fa-regular fa-square-check" />
                <span>AHCA Regulations</span>
              </a>
            </li>
          }


          <li>
            <a
              href="/tutorial"
              hide-off-canvas="true"
              title="Go to video tutorials"
            >
              <i className="nav-icon fa fa-film" />
              <span>Tutorial Videos</span>
            </a>
          </li>

          <li>
            <a href="https://alfboss.com/faq/" target="_blank" rel="noopener noreferrer" hide-off-canvas="true">
              <i className="nav-icon fa fa-question-circle" />
              <span>FAQs</span>
            </a>
          </li>
        </ul>
      </div>
    </PerfectScrollbar>
  );

};

export default Sidebar;
