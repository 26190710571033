import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [task, setTask] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [subscriptionTraining, setSubscriptionTraining] = useState([]);
  const [subscriptionStaff, setSubscriptionStaff] = useState([]);
  const [plans, setPlans] = useState([]);
  const [residentDetails, setResidentDetails] = useState([]);
  const [residentlistDetails, setResidentlistDetails] = useState([]);
  const [residentError, setResidentError] = useState(null);
  const [headerResident, setHeaderResident] = useState([]);
  const [residentStatus, setResidentStatus] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const apiCalledRef = useRef(false);
  const cacheExpiryTime = 3600 * 1000; // 1 hour

  const cacheBuster = useRef(Date.now()).current;

  const specificPathsForFunctions = {
    fetchSubscriptionData: ["/facility-details", "/facility-subscription", "/upgrade-subscription", "/subscription", "/sponsorship", "/subscription-staff"],
    fetchSubscriptionStaffData: ["/facility-details", "/facility-subscription", "/upgrade-subscription", "/subscription", "/sponsorship", "/subscription-staff"],
    fetchSubscriptionTrainingData: ["/facility-details", "/facility-subscription", "/upgrade-subscription", "/subscription", "/sponsorship", "/subscription-staff"],
    fetchPlans: ["/facility-details", "/facility-subscription", "/upgrade-subscription", "/subscription", "/sponsorship", "/subscription-staff", "/trial-subscription-staff"],
  };

  const isCacheValid = (timestampKey) => {
    const timestamp = localStorage.getItem(timestampKey);
    return timestamp && Date.now() - parseInt(timestamp, 10) < cacheExpiryTime;
  };

  const fetchUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/currentuser/?cacheBuster=${cacheBuster}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`, // Add token to the Authorization header
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          clearCachedUser();
          localStorage.removeItem("token");
          navigate("/login");
        }
        return;
      } else {
        const data = await response.json();
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("userTimestamp", Date.now());
        setUser(data);
      }
    } catch { }
  };


  const fetchSubscriptionData = async (url = `${process.env.REACT_APP_API_BASE_URL}/subscriptions/current/?expand=billing_interval,billing_interval.plan&cacheBuster=${cacheBuster}`) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const data = await response.json();
        setSubscription(data);
      }
    } catch (err) {
      // console.error("Failed to fetch Trainings:", err);
    }
  };

  const fetchSubscriptionStaffData = async (url = `${process.env.REACT_APP_API_BASE_URL}/subscriptions/current/?expand=billing_interval,billing_interval.plan&cacheBuster=${cacheBuster}&module=staff`) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const data = await response.json();
        setSubscriptionStaff(data);
      }
    } catch (err) {
      // console.error("Failed to fetch Trainings:", err);
    }
  };

  const fetchSubscriptionTrainingData = async (url = `${process.env.REACT_APP_API_BASE_URL}/subscriptions/current/?expand=billing_interval,billing_interval.plan&cacheBuster=${cacheBuster}&module=trainings`) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const data = await response.json();
        setSubscriptionTraining(data);
      }
    } catch (err) {
      // console.error("Failed to fetch Trainings:", err);
    }
  };

  const fetchPlans = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/plans/?cacheBuster=${cacheBuster}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`, // Add token to the Authorization header
        },
      });

      if (!response.ok) {
        if (response.status === 401) {

          localStorage.removeItem("token"); // Clear invalid token
          navigate("/login"); // Redirect to login
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const data = await response.json();

        setPlans(data); // Set user data to state
      }
    } catch (err) {
      // console.error("Failed to fetch user:", err);
    }
  };

  const fetchResidentDetails = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/residents/?cacheBuster=${cacheBuster}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const data = await response.json();

      if (!response.ok) {
        setResidentError(data.detail);
        return;
      }

      localStorage.setItem("resident", JSON.stringify(data));
      localStorage.setItem("residentTimestamp", Date.now());
      setResidentDetails(data);
      setResidentError(null);
      setResidentStatus(response.status);
    } catch (err) {
      setResidentError(err.message);
    }
  };

  // const fetchHeaderResident = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_BASE_URL}/residents/?cacheBuster=${cacheBuster}&full_name=&is_active=True&order_by=last_name`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );

  //     const data = await response.json();

  //     if (!response.ok) {
  //       setResidentError(data.detail);
  //       return;
  //     }

  //     localStorage.setItem("headerResident", JSON.stringify(data));
  //     localStorage.setItem("headerResidentTimestamp", Date.now());
  //     setHeaderResident(data);
  //     setResidentError(null);
  //   } catch (err) {
  //     setResidentError(err.message);
  //   }
  // };


  const isPathIncluded = (path, list) => list.includes(path);

  const clearCachedUser = () => {
    ["user", "userTimestamp", "resident", "residentTimestamp"].forEach(localStorage.removeItem);
    setUser(null);
    setResidentDetails(null);
    setHeaderResident(null);
  };

  useEffect(() => {

    const clearAllLocalStorage = () => {
      localStorage.clear(); // Clear all localStorage data
      setUser(null);
      setResidentDetails(null);
      setHeaderResident(null);
    };

    if (!token) {
      clearAllLocalStorage(); // Clear all data if no token is found
      navigate("/login"); // Redirect to login
      return;
    }

    if (!apiCalledRef.current) {
      apiCalledRef.current = true;

      if (isCacheValid("userTimestamp")) {
        setUser(JSON.parse(localStorage.getItem("user")));
      } else {
        fetchUser();
      }

      if (isCacheValid("residentTimestamp")) {
        setResidentDetails(JSON.parse(localStorage.getItem("resident")));
      } else {
        fetchResidentDetails();
      }

      // if (isCacheValid("headerResidentTimestamp")) {
      //   setHeaderResident(JSON.parse(localStorage.getItem("headerResident")));
      // } else {
      //   fetchHeaderResident();
      // }

      if (isPathIncluded(location.pathname, specificPathsForFunctions.fetchSubscriptionData)) {
        fetchSubscriptionData();
      }
      if (isPathIncluded(location.pathname, specificPathsForFunctions.fetchSubscriptionStaffData)) {
        fetchSubscriptionStaffData();
      }
      if (isPathIncluded(location.pathname, specificPathsForFunctions.fetchSubscriptionTrainingData)) {
        fetchSubscriptionTrainingData();
      }
      if (isPathIncluded(location.pathname, specificPathsForFunctions.fetchPlans)) {
        fetchPlans();
      }
    }
  }, [location.pathname]);


  return (
    <UserContext.Provider value={{ user, subscription, subscriptionStaff, subscriptionTraining, plans, residentDetails, residentlistDetails, residentError, residentStatus }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);