import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Master from "./partials/master";
import { CircularProgress, Typography, Box } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "./UserContext";

const CircularProgressWithLabel = ({ value }) => {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                variant="determinate"
                value={value}
                sx={{
                    color: "#09a381",
                    width: "120px !important",
                    height: "120px !important",
                }}
                size={120}
                thickness={6}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="h6" component="div" color="white">
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

const DefaultDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const user = useUser();
    const userData = user?.user;
    const residentData = user?.residentDetails;
    const residentError = user?.residentError;
    const residentStatus = user?.residentStatus;
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (!token) {
            navigate("/login");
            return;
        }
    
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev < 80) return prev + 10;
                if (prev < 100) return prev + 0.5;
                return 100;
            });
        }, 300);
    
        return () => {
            clearInterval(interval);
        };
    }, [token, navigate]);
    
    useEffect(() => {
        
        if (residentData.length > 0  && residentStatus === 200 && userData) {
            setLoading(false);
            setProgress(100);
        }
        
        if (residentData.length === 0  && residentStatus === 200 && userData) {
            setLoading(false);
            setProgress(100);
        }

        if (residentError) {
            setLoading(false);
            setProgress(100);
        }
    }, [residentStatus, userData, residentError]);
    

    return (
        <Master>
            {loading ? (
                <div className="overlay">
                    <CircularProgressWithLabel value={progress} />
                </div>
            ) : (
                <div className="content">
                    <div className="dashboard-content">
                        <div className="dash-heading">
                            <h1 className="dash-heading-text">
                                Welcome to AlfBoss, {userData?.user?.first_name} {userData?.user?.last_name}!
                            </h1>
                        </div>
                        <div className="dash-para">
                            <p className="para-text">
                                We’re excited to have you on board. Your dashboard is now ready.
                            </p>
                            <p className="para-text">
                                Start managing your resident data with ease and discover the tools designed to simplify your workflow.
                            </p>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </Master>
    );
};

export default DefaultDashboard;
