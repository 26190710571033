import React, { useEffect } from "react";
import { Box, Typography, Container } from "@mui/material";
import Master from "./partials/master";
import { useNavigate } from "react-router-dom";

const MaintenancePage = () => {
  const navigate = useNavigate();
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (!token) {
            navigate("/login");
            return;
        }
      },[token, navigate]);

  return (
    <div className="contents">
      <Master>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#f4f4f4",
            textAlign: "center",
          }}
        >
          <i class="fa-solid fa-screwdriver-wrench"></i>
          <Typography variant="h3" sx={{ mt: 2, color: "#333" }}>
            We’re Under Maintenance
          </Typography>
          <Typography variant="h6" sx={{ mt: 1, color: "#555" }}>
            Our website is currently undergoing scheduled maintenance.
            <br /> We’ll be back soon. Thanks for your patience!
          </Typography>
        </Box>
      </Master>
    </div>
  );
};

export default MaintenancePage;
